import { FC, ReactChild } from 'react'
import { Contact } from 'common/components/Contact'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { Typography, TypographyVariants } from 'common/typography'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from '../styles.module.sass'

interface ContactListProps {
  title: string | ReactChild
  contacts: any[]
  emptyHelper?: any
  searchString: string
  openedProfileUid: string
  trustedUids: string[]
  allContactsUids: string[],
  showTrustButtons?: boolean
}

export const ContactList: FC<ContactListProps> = ({
  title,
  contacts,
  emptyHelper,
  searchString,
  openedProfileUid,
  trustedUids,
  allContactsUids,
  showTrustButtons
}) => {
  const { trust, untrust } = useContactActions()
  const { navigateToProfile } = useProfileNavigation()

  const renderContacts = (title: string | ReactChild) => {
    if (contacts.length > 0) {
      return contacts.map(({
        displayName, last_name, photoURL, uid, photo, job, username
      }: any) => (
        <Contact
          key={`${uid}${title}`}
          isTrusted={trustedUids.includes(uid)}
          isContact={allContactsUids.includes(uid)}
          searchString={searchString}
          onClick={() => navigateToProfile(uid, username)}
          name={displayName || last_name}
          photoUrl={photoURL}
          photo={photo}
          jobTitle={job?.title || ''}
          active={uid === openedProfileUid}
          uid={uid}
          action={trustedUids.includes(uid) ? untrust : trust}
          showTrustButtons={showTrustButtons}
        />
      ))
    }

    if (emptyHelper) {
      return (
        <Contact
          isTrusted={false}
          onClick={() => null}
          searchString={searchString}
          name={emptyHelper.displayName || emptyHelper.last_name}
          photoUrl={emptyHelper.photoURL}
          photo={emptyHelper.photo}
          jobTitle={emptyHelper.job?.title || ''}
          active={false}
          uid={emptyHelper.uid}
          isMocUser
          isContact
        />
      )
    }

    return null
  }

  return (
    <>
      <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="span" className={styles.subtitle}>
        {title}
      </Typography>
      {renderContacts(title)}
    </>
  )
}

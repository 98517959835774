import { FC } from 'react'
import cn from 'classnames'
import { useDesktopMediaQuery, useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { SideBar } from 'features/NavBar/components/SideBar'
import styles from './styles.module.sass'

interface IPageHeader {
  pageName?: string
  children?: any
  className?: string
  isMiniNavbar?: boolean
  isLoading?: boolean
}

export const PageHeader: FC<IPageHeader> = ({
  pageName, children, className, isMiniNavbar = false, isLoading = false
}) => {
  const isDesktop = useDesktopMediaQuery()
  const isMobile = useMobileMediaQuery()

  return (
    <div className={cn(styles.container, isMobile && styles.mobile, className, isMiniNavbar && styles.miniNavbar)}>
      {!isDesktop && (
        <div className={cn(!isMiniNavbar && styles.sideBar)}>
          <SideBar />
        </div>
      )}
      <div className={cn(styles.headerTitle, (!isMiniNavbar && !children) && styles.sideBar)}>
        <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="span">
          {pageName}
        </Typography>
        {(!isDesktop) && isLoading && <PreloaderIcon stroke="#D7DFED" />}
        {isDesktop && isLoading && <div className={styles.loading}><PreloaderIcon stroke="#D7DFED" /></div>}
      </div>
      {children}
      {!children && <div />}
    </div>
  )
}

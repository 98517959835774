import React, {
  FC,
  useCallback,
  useEffect
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { LENGTH_LIMITS, REGEX, SYMBOLS } from 'common/constants'
import { useLinks } from 'common/hooks/useLinks'
import { FormInput } from 'features/FormInput_V2'
import { LocationField } from 'features/FormInput_V2/LocationField'
import { checkUsernameUnique, updateMyProfile } from 'features/MyProfile/actions'
import { EditLinks } from 'features/MyProfile/components/EditLinks'
import { JobTitleField } from 'features/MyProfile/components/VacancySpeciality_V2/JobTitleField'
import { PROFILE_FIELDS } from 'features/MyProfile/constants'
import { getMyProfile } from 'features/MyProfile/selectors'
import { SocialLinkType, UserType } from 'features/MyProfile/types'
import {
  selectEditProfileTranslations, selectErrorMsgsTranslations,
  selectOnboardingTranslations, selectProfileTranslations
} from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IEditHeaderForm {
  onFinish: () => void;
  onCloseWithDiscard: () => void;
}

export const EditHeaderForm: FC<IEditHeaderForm> = ({ onFinish, onCloseWithDiscard }) => {
  const dispatch = useDispatch()
  const profileTranslations = useSelector(selectProfileTranslations)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const editProfileTranslations = useSelector(selectEditProfileTranslations)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const profile = useSelector(getMyProfile) as UserType
  const initialLinks = profile.links || []
  const { validateLinks } = useLinks(initialLinks)
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
    clearErrors,
    trigger,
    watch,
    setValue,
    setError
  } = useFormContext<{
    [PROFILE_FIELDS.FIRST_NAME]: string,
    [PROFILE_FIELDS.USER_NAME]?: string,
    [PROFILE_FIELDS.LAST_NAME]: string,
    job: {
      [PROFILE_FIELDS.COMPANY]: string,
      [PROFILE_FIELDS.JOB_TITLE]: string
    },
    [PROFILE_FIELDS.LOCATION]: string
    [PROFILE_FIELDS.LINKS]: SocialLinkType[]
  }>()

  const {
    [PROFILE_FIELDS.FIRST_NAME]: firstName,
    [PROFILE_FIELDS.LAST_NAME]: lastName,
    [PROFILE_FIELDS.USER_NAME]: username,
    job: {
      [PROFILE_FIELDS.COMPANY]: company
    }
  } = watch()

  const debouncedCheckUsernameUnique = useCallback(
    debounce(async (username: string, isSubmitting?: boolean) => {
      if (isSubmitting || !username) return
      dispatch(checkUsernameUnique(username, (success: boolean) => {
        if (success) {
          clearErrors(PROFILE_FIELDS.USER_NAME)
        } else {
          if (isSubmitting) return
          setError(PROFILE_FIELDS.USER_NAME,
            { type: 'custom', message: editProfileTranslations.usernameAlreadyExistsError })
        }
      }))
    }, 500), []
  )

  useEffect(() => {
    if (username && username.length < LENGTH_LIMITS.MIN.USER_NAME) {
      setError(
        PROFILE_FIELDS.USER_NAME,
        { type: 'custom', message: errorMsgsTranslations.helperTextLimitMin?.replace('%', `${LENGTH_LIMITS.MIN.USER_NAME}`) }
      )
    } else if (!REGEX.USER_NAME.test(username || '')) {
      setError(PROFILE_FIELDS.USER_NAME, {
        type: 'custom',
        message: editProfileTranslations.userNameRegexError
      })
    } else if (!isSubmitting && username && username !== profile.username) {
      debouncedCheckUsernameUnique(username, isSubmitting)
    } else if (username) {
      clearErrors(PROFILE_FIELDS.USER_NAME)
    }
  }, [username, profile.username, errors, isSubmitting, debouncedCheckUsernameUnique])

  const SECTIONS = [
    {
      title: editProfileTranslations.firstNameTitle,
      name: PROFILE_FIELDS.FIRST_NAME,
      maxCharCount: LENGTH_LIMITS.MAX.FIRST_NAME,
      value: firstName,
      required: true,
      error: errors[PROFILE_FIELDS.FIRST_NAME],
      errorMsg: onboardingTranslations.helperFirstName,
      showMaxCharCount: true,
      placeholder: `${editProfileTranslations.firstNameTitle || ''}*`,
      onBlur: () => {
        trigger(PROFILE_FIELDS.FIRST_NAME)
      }
    },
    {
      title: editProfileTranslations.lastNameTitle,
      name: PROFILE_FIELDS.LAST_NAME,
      maxCharCount: LENGTH_LIMITS.MAX.LAST_NAME,
      value: lastName,
      required: true,
      error: errors[PROFILE_FIELDS.LAST_NAME],
      errorMsg: onboardingTranslations.helperLastName,
      showMaxCharCount: true,
      placeholder: `${editProfileTranslations.lastNameTitle}*`,
      onBlur: () => {
        trigger(PROFILE_FIELDS.LAST_NAME)
      }
    },
    {
      title: editProfileTranslations.userNameTitle,
      name: PROFILE_FIELDS.USER_NAME,
      maxCharCount: LENGTH_LIMITS.MAX.USER_NAME,
      minCharCount: LENGTH_LIMITS.MIN.USER_NAME,
      value: username,
      required: true,
      error: errors[PROFILE_FIELDS.USER_NAME],
      errorMsg: editProfileTranslations.emptyUserNameError,
      showMaxCharCount: true,
      placeholder: `${editProfileTranslations.userNameTitle}*`,
      regex: REGEX.USER_NAME,
      regexValidationMessage: editProfileTranslations.userNameRegexError,
      onBlur: () => {
        trigger(PROFILE_FIELDS.USER_NAME)
        if (username && username.length < LENGTH_LIMITS.MIN.USER_NAME) {
          setError(PROFILE_FIELDS.USER_NAME,
            { type: 'custom', message: errorMsgsTranslations.helperTextLimitMin?.replace('%', `${LENGTH_LIMITS.MIN.USER_NAME}`) })
        } else if (username && username !== profile.username) {
          debouncedCheckUsernameUnique(username)
        } else if (username) {
          clearErrors(PROFILE_FIELDS.USER_NAME)
        }
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const lowercasedUsername = e.target.value.toLowerCase()
        setValue(PROFILE_FIELDS.USER_NAME, lowercasedUsername, { shouldDirty: true })
        clearErrors(PROFILE_FIELDS.USER_NAME)
      }
    },
    {
      title: editProfileTranslations.locationTitle,
      customComponent: LocationField,
      name: PROFILE_FIELDS.LOCATION,
      error: errors[PROFILE_FIELDS.LOCATION],
      maxCharCount: LENGTH_LIMITS.MAX.LOCATION,
      showMaxCharCount: true,
      required: false,
      isMultiselect: false,
      placeholder: editProfileTranslations.locationTitle
    },
    {
      title: editProfileTranslations.currentJobTitle,
      customComponent: JobTitleField,
      name: `job.${PROFILE_FIELDS.JOB_TITLE}`,
      error: (errors.job as Record<string, any>)?.[PROFILE_FIELDS.JOB_TITLE],
      maxCharCount: LENGTH_LIMITS.MAX.JOB_TITLE,
      isOnEditing: true,
      required: false,
      showMaxCharCount: true,
      placeholder: editProfileTranslations.currentJobTitle
    },
    {
      title: editProfileTranslations.companyTitle,
      name: `job.${PROFILE_FIELDS.COMPANY}`,
      maxCharCount: LENGTH_LIMITS.MAX.COMPANY,
      showMaxCharCount: true,
      value: company,
      error: (errors.job as Record<string, any>)?.[PROFILE_FIELDS.COMPANY],
      placeholder: editProfileTranslations.companyTitle
    }
  ]

  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    await handleSubmit(async (data) => {
      const dataToSave = { ...data }
      const isLinksInvalid = validateLinks()
      if (isLinksInvalid) return null
      if (data[PROFILE_FIELDS.USER_NAME] === profile.username) {
        delete dataToSave[PROFILE_FIELDS.USER_NAME]
      }
      if (dataToSave[PROFILE_FIELDS.LINKS]) {
        dataToSave[PROFILE_FIELDS.LINKS] = dataToSave[PROFILE_FIELDS.LINKS]
          .reduce((acc, link) => {
            if (link.url?.trim()) {
              return [...acc, { name: '', url: link.url }]
            }
            return acc
          }, [] as SocialLinkType[])
      }
      if (dataToSave[PROFILE_FIELDS.USER_NAME]) {
        // TODO: make this functionality reusable
        return dispatch(checkUsernameUnique(
          dataToSave[PROFILE_FIELDS.USER_NAME],
          async (success: boolean) => {
            if (success) {
              await dispatch(updateMyProfile(
                {
                  ...dataToSave,
                  [PROFILE_FIELDS.LOCATION]:
                    dataToSave[PROFILE_FIELDS.LOCATION]
                      .replace(SYMBOLS.FE_LOCATION_SEPARATOR, SYMBOLS.BE_LOCATION_SEPARATOR)
                },
                onFinish
              ))
            } else {
              setError(PROFILE_FIELDS.USER_NAME,
                { type: 'custom', message: editProfileTranslations.usernameAlreadyExistsError })
            }
          }
        ))
      }
      return dispatch(updateMyProfile(
        {
          ...dataToSave,
          [PROFILE_FIELDS.LOCATION]:
            dataToSave[PROFILE_FIELDS.LOCATION].replace(SYMBOLS.FE_LOCATION_SEPARATOR, SYMBOLS.BE_LOCATION_SEPARATOR)
        },
        onFinish
      ))
    }, () => validateLinks())()
  }

  const onOptionSelect = useCallback((option: string, fieldName) => {
    setValue(fieldName, option, { shouldDirty: true })
    clearErrors(fieldName)
  }, [clearErrors])

  const handleSubmitForm = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    if (isDirty) {
      await onSubmit(e)
    } else {
      onFinish()
    }
  }

  return (
    <form onSubmit={handleSubmitForm}>
      {SECTIONS.map(({
        title,
        name,
        maxCharCount,
        minCharCount,
        error,
        errorMsg,
        value,
        required,
        isMultiselect,
        isOnEditing,
        showMaxCharCount,
        placeholder,
        onBlur,
        onChange,
        regex,
        regexValidationMessage,
        customComponent: CustomComponent
      }) => {
        if (CustomComponent) {
          return (
            <CustomComponent
              key={name}
              fieldName={name}
              onOptionSelect={(option: string) => onOptionSelect(option, name)}
              title={title}
              error={error?.message}
              maxCharCount={maxCharCount}
              minCharCount={minCharCount}
              isMultiselect={isMultiselect}
              isOnEditing={isOnEditing}
              isRequired={required}
              showMaxCharCount={showMaxCharCount}
              placeholder={placeholder}
            />
          )
        }

        return (
          <FormInput
            key={name}
            clearErrors={clearErrors}
            title={title}
            maxCharCount={maxCharCount}
            minCharCount={minCharCount}
            isRequired={required}
            register={register}
            fieldName={name}
            defaultValue={value}
            error={error?.message}
            errorMsg={errorMsg}
            trigger={trigger}
            isOnEditing
            showMaxCharCount={showMaxCharCount}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            regex={regex}
            regexValidationMessage={regexValidationMessage}
          />
        )
      })}
      <EditLinks
        links={initialLinks}
        title={editProfileTranslations.addWebsite}
        placeholder={profileTranslations.url}
        allowMultiple
      />
      <div className={styles.editModalButtons}>
        <SecondaryButton
          title={profileTranslations.cancel}
          onClick={onCloseWithDiscard}
          disabled={isSubmitting}
        />
        <PrimaryButton
          type="submit"
          className={styles.primaryButton}
          title={editProfileTranslations.saveButton}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </form>
  )
}

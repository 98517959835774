import { ProfileType, UserType } from 'features/MyProfile/types'

export const filterUsersByTrustLevel = (
  users: ProfileType[],
  trustLevels: { [key: string]: number },
  level: number = 2
): ProfileType[] => {
  return users.filter((user) => trustLevels[user.uid] === level)
}

export const combineContactsAndTrustUsers = (
  contacts: UserType[],
  trustUsers: ProfileType[],
  trustLevels: { [key: string]: number },
  level: number = 2
) => {
  return trustUsers.reduce((acc, user) => {
    if (trustLevels[user.uid] === level && !acc.some((contact) => contact.uid === user.uid)) {
      acc.push(user)
    }
    return acc
  }, contacts.slice())
}

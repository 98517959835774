import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { TransparentButton } from 'common/components/Button_V2/TransparentButton'
import { ROUTES } from 'common/constants'
import { Modal } from 'features/Modal_V2'
import { actions } from 'features/MyProfile/actions'
import { selectProfileTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ILimitExceededTrustModal {
  isFullTrustContacts: boolean
}

export const LimitExceededTrustModal: FC<ILimitExceededTrustModal> = ({ isFullTrustContacts }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const profileTranslations = useSelector(selectProfileTranslations)
  const onClose = () => dispatch(actions.setIsFullTrustContacts(false))

  useEffect(() => {
    if (isFullTrustContacts) dispatch(actions.setIsEditTrustContacts(false))
  }, [isFullTrustContacts])

  return (
    <Modal
      onClose={onClose}
      isOpen={isFullTrustContacts}
      width={470}
      title={profileTranslations.trustLimit}
    >
      <div className={styles.wrapper}>
        <p>{profileTranslations.trustLimitMessage.replaceAll('\\n', '\n')}</p>
        <div className={styles.buttons}>
          <SecondaryButton
            className={styles.cancel}
            title={profileTranslations.buttonTextOk}
            onClick={onClose}
          />
          <TransparentButton
            className={styles.edit}
            title={profileTranslations.editTrusts}
            onClick={() => {
              onClose()
              dispatch(actions.setIsEditTrustContacts(true))
              history.push(ROUTES.CONTACTS)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

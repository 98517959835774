import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  or,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  where
} from 'firebase/firestore'
import type { Room } from 'twilio-video'
import { profileAPI, usersAPI } from 'api'
import { actions as commonActions } from 'common/actions'
import {
  API_STATUS_CODES,
  BUNDLE,
  EXPLORE_CARD_TIME_FORMAT_BE,
  LOCAL_STORAGE_VALUES,
  NOTIFICATION_HIDE_DELAY_MS,
  STATUS_VACANCIES
} from 'common/constants'
import { apiCodes, AppStateType } from 'common/types'
import { dataURLtoFile } from 'common/utils/dataURLtoFile'
import { getDeviceID } from 'common/utils/getDeviceID'
import LocalStorageService from 'common/utils/LocalStorageService'
import { isNumber } from 'common/utils/numbers'
import envConfig from 'config'
import { actions as actionsConversations, addChat, sendMessage } from 'features/Conversations/actions'
import { SpecialtyOffer, SpecialtyOfferResponse, VacancyOfferOrFavorType } from 'features/Conversations/types'
import { actions as actionsHome, addMemoizedUser } from 'features/Home/actions'
import { NotificationHistoryTypes } from 'features/Home/types'
import {
  EditProfileElementsTypes, EnumTimeSlots, VacancyType
} from 'features/MyProfile/types'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import {
  ErrorModalTypes,
  IncomingCallType,
  NotificationHistoryJobOfferType,
  NotificationJobOfferTypes,
  NotificationsHistoryType,
  NotificationStatusTypes,
  NotificationTypes,
  ValueNotificationsHistoryType
} from 'features/Notifications/types'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { actions as actionsVideoChat, connectToVideoRoom } from 'features/VideoChat/actions'
import { firestoreDb } from 'store/store'
import type {
  ContactsType,
  ProfileType,
  ResponseCallNowType,
  ResultCompareContactsType,
  ResultCompareInstanceCallType,
  SlotsType,
  ThunkType,
  TrustedUserType
} from './types'
import { getTokenFcm, normalizeIntroNotification } from './utils'

export const actions = {
  setMyProfile: (profile: any) => ({ type: 'PROFILE__SET_MY_PROFILE', profile } as const),
  addContact: (contactUid: string) => ({ type: 'PROFILE__ADD_CONTACT', contactUid } as const),
  addContacts: (contactUids: string[]) => ({ type: 'PROFILE__ADD_CONTACTS', contactUids } as const),
  setMyVacancies: (vacancies: VacancyType[]) => ({ type: 'PROFILE__SET_MY_VACANCIES', vacancies } as const),
  setMyVacanciesLoading: (isLoading: boolean) => ({ type: 'PROFILE__SET_MY_VACANCIES_LOADING', isLoading } as const),
  setTrustUsers: (trustUsers: { [key: string]: TrustedUserType }) => ({ type: 'PROFILE__SET_TRUST_USERS', trustUsers } as const),
  setUidsTrustedByMyContacts: (commonTrustedContactList: { [uid: string]: string[] }) => (
    { type: 'PROFILE__SET_COMMON_TRUSTED_CONTACTS', commonTrustedContactList } as const),
  deleteContact: (payload: string) => ({ type: 'PROFILE__DELETE_MY_CONTACT', payload } as const),
  setFavoredVacancies: (favors: VacancyOfferOrFavorType[]) => ({ type: 'PROFILE__SET_ALL_MY_FAVORS_FOR_VACANCIES', favors } as const),
  setFavoredVacancy: (favor: VacancyOfferOrFavorType) => ({ type: 'PROFILE__SET_MY_FAVOR_FOR_VACANCY', favor } as const),
  setTrustedUids: (trustedUids: string[]) => ({ type: 'PROFILE__SET_MY_PROFILE_TRUST', trustedUids } as const),
  setIsFullTrustContacts: (isFullTrustContacts: boolean) => ({ type: 'PROFILE__SET_MY_PROFILE_IS_FULL_TRUST_CONTACTS', isFullTrustContacts } as const),
  setIsEditTrustContacts: (isEditTrustContacts: boolean) => ({ type: 'PROFILE__SET_MY_PROFILE_EDIT_TRUST_CONTACTS', isEditTrustContacts } as const),
  setIsOpenContactsModal: (isOpenContactsModal: boolean) => ({ type: 'PROFILE__SET_MY_PROFILE_IS_OPEN_CONTACTS_MODAL', isOpenContactsModal } as const),
  selectIsLoadingTrustOrUntrust: (isLoading: boolean) => ({ type: 'PROFILE__SET_TRUST_OR_UNTRUST_LOADING', isLoading } as const),
  updateMyProfilePhoto: (photoURL: string, photo: string) => ({ type: 'PROFILE__UPDATE_MY_PROFILE_PHOTO', photoURL, photo } as const),
  setIsActiveFcm: (isActiveFcm: boolean) => ({ type: 'PROFILE__SET_IS_ACTIVE_FCM', isActiveFcm } as const),
  toggleLoader: (loader: string) => (
    { type: 'PROFILE__TOGGLE_LOADER', loader } as const
  ),
  updateMySlots: (action: EnumTimeSlots, slot: string | SlotsType) => (
    { type: 'PROFILE__UPDATE_MY_SLOTS', payload: { action, slot } } as const
  ),
  addNewVacancy: (vacancy: any) => ({ type: 'PROFILE__ADD_NEW_VACANCY', vacancy } as const),
  editVacancy: (vacancy: any) => ({ type: 'PROFILE__EDIT_VACANCY', vacancy } as const),
  closeVacancy: (vacancyId: string) => ({ type: 'PROFILE__CLOSE_VACANCY', vacancyId } as const),
  deleteVacancy: (vacancyId: string) => ({ type: 'PROFILE__DELETE_VACANCY', vacancyId } as const),
  setOfferedVacancies: (offers: VacancyOfferOrFavorType[]) => ({ type: 'PROFILE__SET_ALL_OFFERS_FOR_VACANCIES', offers } as const),
  setSpecialities: (payload: any) => ({ type: 'PROFILE__SET_SPECIALITIES', payload } as const),
  addFavoredSpecialty: (favor: SpecialtyOffer) => ({ type: 'PROFILE__ADD_MY_FAVOR_FOR_SPECIALTY', favor } as const),
  setFavoredSpecialties: (favors: SpecialtyOffer[]) => ({ type: 'PROFILE__SET_ALL_MY_FAVORS_FOR_SPECIALTIES', favors } as const),
  addNewSpeciality: (payload: any) => ({ type: 'PROFILE__ADD_NEW_SPECIALITY', payload } as const),
  editSpeciality: (payload: any) => ({ type: 'PROFILE__EDIT_SPECIALITY', payload } as const),
  deleteSpeciality: (payload: any) => ({ type: 'PROFILE__DELETE_SPECIALITY', payload } as const),
  closeSpecialty: (payload: any) => ({ type: 'PROFILE__CLOSE_SPECIALITY', payload } as const)
}

const normalizeJobOfferNotification = (
  id: string,
  doc: ValueNotificationsHistoryType
): NotificationHistoryJobOfferType | null => {
  const isEmployer = [
    NotificationTypes.VACANCY,
    NotificationTypes.NEW_VACANCY,
    NotificationTypes.MODIFIED_VACANCY,
    NotificationTypes.CLOSED_VACANCY
  ].includes(doc.type)

  if (!doc.data || !doc.data.id || !(isEmployer ? doc.data.employer : doc.data.employee)) {
    return null
  }

  return {
    id,
    jobDetails: doc.data as VacancyType,
    status: doc.status,
    type: doc.type as NotificationJobOfferTypes,
    user: isEmployer ? doc.data.employer : doc.data.employee,
    isEmployer
  }
}

const addNewJobOfferNotificationHistory = (
  notificationId: string,
  doc: ValueNotificationsHistoryType
): ThunkType => (dispatch) => {
  const normalizedNotification = normalizeJobOfferNotification(notificationId, doc)
  if (!normalizedNotification) return
  dispatch(actionsNotifications.addItemInHistory(
    notificationId,
    normalizedNotification
  ))
  dispatch(actionsHome.prependNotificationHistoryId(notificationId, NotificationHistoryTypes.JOB_OFFER))
}

const addOrUpdateNotificationHistoryJobOffer = (
  newNotificationId: string,
  doc: ValueNotificationsHistoryType
): ThunkType => async (dispatch, getState) => {
  const { history } = getState().notifications
  const [historyId] = Object.entries(history).find(([, jobOffer]) => {
    return doc.data.id && (jobOffer as NotificationHistoryJobOfferType).jobDetails?.id === doc.data.id
  }) ?? []

  if (historyId) {
    const normalizedNotification = normalizeJobOfferNotification(newNotificationId, doc)
    if (!normalizedNotification) return
    dispatch(actionsNotifications.replaceItemInHistory(
      historyId,
      normalizedNotification
    ))
  } else {
    dispatch(addNewJobOfferNotificationHistory(newNotificationId, doc))
  }
}
const deleteByJobOfferId = (
  jobOfferId: string
): ThunkType => async (dispatch, getState) => {
  const { history } = getState().notifications
  const [historyId] = Object.entries(history).find(([, jobOffer]) => {
    return jobOfferId && (jobOffer as NotificationHistoryJobOfferType).jobDetails?.id === jobOfferId
  }) ?? []

  if (historyId) {
    dispatch(actionsHome.deleteJobOfferNotification(historyId))
    dispatch(actionsNotifications.deleteItemFromHistory(historyId))
  }
}

const deleteNotificationsByUserId = (
  uid: string
): ThunkType => async (dispatch, getState) => {
  const { history } = getState().notifications
  const notifications = Object.entries(history).filter(([, data]) => {
    const isNotIntroNotification =
      data.type !== NotificationTypes.INTRO
      && data.type !== NotificationTypes.INTRO_YOU
      && data.type !== NotificationTypes.MY_INTRO
    return uid && (data as unknown as NotificationHistoryJobOfferType).user?.uid === uid && isNotIntroNotification
  }) ?? []
  const historyIds = notifications.map(([id]) => id)
  if (historyIds.length) {
    dispatch(actionsHome.deleteJobOfferNotifications(historyIds))
    dispatch(actionsNotifications.deleteItemsFromHistory(historyIds))
  }
}

const deleteNotificationById = (
  notificationId: string,
  doc: ValueNotificationsHistoryType & { resume_id?: string, vacancy_id?: string }
): ThunkType => (dispatch) => {
  dispatch(actionsHome.deleteIntroNotification(notificationId))
  const jobIdToRemove = doc.resume_id || doc.vacancy_id || ''
  if (jobIdToRemove) dispatch(deleteJobOfferById(jobIdToRemove))
}

const deleteJobOfferById = (jobId: string): ThunkType => (dispatch, getState) => {
  const { notifications: { history } } = getState()
  const [historyId] = Object.entries(history).find(([, jobOffer]) => {
    return jobId && (jobOffer as NotificationHistoryJobOfferType).jobDetails?.id === jobId
  }) || []
  if (historyId) {
    dispatch(actionsHome.deleteJobOfferNotification(historyId))
    dispatch(actionsNotifications.deleteItemFromHistory(historyId))
  }
}

const callFinishedNotification = (room: Room | null, contactUid: string): ThunkType => (dispatch, getState) => {
  const {
    profile: { profile },
    surf: { memoizedUsers }
  } = getState()
  if (profile?.contacts) {
    const { displayName } = memoizedUsers[contactUid]
    dispatch(actionsNotifications.addAnyMsg({ msg: `${displayName} Call is ended`, uid: uuidv4() }))
    clearVideoChat(room, dispatch)
  }
}

export const trustUser = (uids: string[]): ThunkType => async (dispatch, getState) => {
  const { profile: { profile }, translations: { data } } = getState()
  if (!profile) return
  const newTrustedUids = Array.from(new Set(uids))
  if (newTrustedUids.length > +(data.profile.maxCountTrusts)) {
    dispatch(actions.setIsFullTrustContacts(true))
    dispatch(actions.setIsOpenContactsModal(false))
  } else {
    dispatch(actions.selectIsLoadingTrustOrUntrust(true))
    dispatch(actions.setIsFullTrustContacts(false))
    const status = await usersAPI.trustUsers(newTrustedUids)
    if (status === apiCodes.error) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: 'Failed to trust the user'
      }))
      return
    }
    await dispatch(getTrustLevelsAsync())
    dispatch(actions.setTrustedUids(newTrustedUids))
    dispatch(actions.selectIsLoadingTrustOrUntrust(false))
  }
}

export const untrustUser = (uids: string[]): ThunkType => async (dispatch, getState) => {
  const { profile: { profile } } = getState()
  if (!profile) return
  const newTrustedUids = Array.from(new Set(uids))
  dispatch(actions.selectIsLoadingTrustOrUntrust(true))
  const status = await usersAPI.trustUsers(newTrustedUids)
  if (status === apiCodes.error) {
    dispatch(actionsNotifications.addErrorMsg({
      type: ErrorModalTypes.DEFAULT,
      description: 'Failed to untrust the user'
    }))
    return
  }
  await dispatch(getTrustLevelsAsync())
  dispatch(actions.setTrustedUids(newTrustedUids))
  dispatch(actions.selectIsLoadingTrustOrUntrust(false))
}

const cancelMeeting = (uid: string, date: string) => {
  const meetingId = `${uid}${LOCAL_STORAGE_VALUES.SEPARATOR}${date}`
  const scheduledMeetings = JSON.parse(LocalStorageService.getItem(LOCAL_STORAGE_VALUES.SCHEDULED_MEETINGS) || '[]')
  scheduledMeetings.filter((meeting: string) => meeting !== meetingId)
  LocalStorageService.setItem(
    LOCAL_STORAGE_VALUES.SCHEDULED_MEETINGS,
    scheduledMeetings.filter((meeting: string) => meeting !== meetingId)
  )
}

const clearVideoChat = (room: Room | null, dispatch: (action: any) => void) => {
  if (room) room.disconnect()
  dispatch(actionsNotifications.removeIncomingCall())
  dispatch(actionsVideoChat.reset())
  dispatch(actionsVideoChat.setRoom(null))
}

const jobOfferDocTypes = [
  NotificationTypes.VACANCY,
  NotificationTypes.RESUME,
  NotificationTypes.NEW_VACANCY,
  NotificationTypes.NEW_RESUME,
  NotificationTypes.MODIFIED_VACANCY,
  NotificationTypes.MODIFIED_RESUME
]

export const normalizeNotificationHistory = (
  docs: QueryDocumentSnapshot[],
  dispatch: (action: any) => void
) => {
  const querySnapshotResult = docs.reduce((acc, snapshot) => {
    const doc = snapshot.data() as ValueNotificationsHistoryType
    const isNewDateLater = new Date(doc.ts).getTime() > new Date(acc.maxNotificationDate).getTime()
    if (!acc.maxNotificationDate || isNewDateLater) {
      acc.maxNotificationDate = doc.ts
    }
    if (jobOfferDocTypes.includes(doc.type)) {
      // TODO: Update the logic when the BE archives updated resumes/vacancies
      const [historyId, jobOffer] = Object.entries(acc.notificationHistory).find(([, jobOffer]) => {
        return doc.data.id && (jobOffer as NotificationHistoryJobOfferType).jobDetails?.id === doc.data.id
      }) ?? []
      if (historyId) {
        const { jobDetails } = jobOffer as NotificationHistoryJobOfferType
        const foundNotificationUpdatedTime: number = new Date(jobDetails.updated || jobDetails.ts || '').getTime()
        if (new Date(doc.data.updated).getTime() > foundNotificationUpdatedTime) {
          const normalizedNotification = normalizeJobOfferNotification(snapshot.id, doc)
          if (normalizedNotification) acc.notificationHistory[historyId] = normalizedNotification
        }
      }
      const normalizedNotification = normalizeJobOfferNotification(snapshot.id, doc)
      if (normalizedNotification) {
        acc.notificationHistoryIds.jobOffer.push(snapshot.id)
        acc.notificationHistory[snapshot.id] = normalizedNotification
      }
    } else if (doc.type === NotificationTypes.CLOSED_VACANCY) {
      const [historyId, jobOffer] = Object.entries(acc.notificationHistory).find(([, jobOffer]) => {
        return doc.data.id && (jobOffer as NotificationHistoryJobOfferType).data?.id === doc.data.id
      }) ?? []
      if (historyId) {
        const { jobDetails } = jobOffer as NotificationHistoryJobOfferType
        const foundNotificationUpdatedTime: number = new Date(jobDetails.updated || jobDetails.ts || '').getTime()
        if (new Date(doc.data.updated).getTime() > foundNotificationUpdatedTime) {
          const normalizedNotification = normalizeJobOfferNotification(snapshot.id, doc)
          if (normalizedNotification) acc.notificationHistory[historyId] = normalizedNotification
        }
      }
      const normalizedNotification = normalizeJobOfferNotification(snapshot.id, doc)
      if (normalizedNotification) {
        acc.notificationHistoryIds.jobOffer.push(snapshot.id)
        acc.notificationHistory[snapshot.id] = normalizedNotification
      }
    } else if (doc.type === NotificationTypes.INTRO) {
      if (!doc.data.broker || !doc.data.contacts?.length) return acc
      const normalizedNotification = normalizeIntroNotification(doc, snapshot.id)
      dispatch(addMemoizedUser(normalizedNotification.data.contacts[0]?.uid, false, {}))
      acc.notificationHistoryIds.intro.push(snapshot.id)
      acc.notificationHistory[snapshot.id] = normalizedNotification
    } else {
      acc.notificationHistory[snapshot.id] = { ...doc, id: snapshot.id }
    }

    return acc
  }, {
    notificationHistory: {} as NotificationsHistoryType,
    notificationHistoryIds: { intro: [], jobOffer: [] } as { intro: string[]; jobOffer: string[] },
    maxNotificationDate: ''
  })

  return querySnapshotResult
}

export const getCardsWithLimit = async (
  queries: ((limit: number) => Query)[],
  pageSize: number,
  index = 0,
  limit = 0,
  loadedData = [] as QueryDocumentSnapshot[]
): Promise<QueryDocumentSnapshot[]> => {
  const query = queries[index]
  if (!query) return loadedData
  const data = await getDocs(query(limit || pageSize)).catch(() => null)
  loadedData.push(...(data?.docs || []))
  const newLimit = pageSize - loadedData.length
  if (newLimit <= 0) return loadedData
  return getCardsWithLimit(queries, pageSize, index + 1, newLimit, loadedData)
}

export const getHomeCardsFirstPage = async (uid: string, dispatch: (action: any) => void) => {
  const PAGE_SIZE = 5

  const notificationCollection = collection(firestoreDb, `profiles/${uid}/notifications`)
  const getActiveIntroQuery = (maxCount: number) => query(
    notificationCollection,
    and(
      where('type', '==', NotificationTypes.INTRO),
      where('status', '==', NotificationStatusTypes.ACTIVE)
    ),
    orderBy('ts', 'desc'),
    limit(maxCount)
  )
  const getJobOffersQuery = (maxCount: number) => query(
    notificationCollection,
    and(
      or(...jobOfferDocTypes.map((type) => where('type', '==', type))),
      where('status', '==', NotificationStatusTypes.ACTIVE)
    ),
    orderBy('ts', 'desc'),
    limit(maxCount)
  )
  const getReadIntroQuery = (maxCount: number) => query(
    notificationCollection,
    and(
      where('type', '==', NotificationTypes.INTRO),
      where('status', '==', NotificationStatusTypes.READ)
    ),
    orderBy('ts', 'desc'),
    limit(maxCount)
  )

  const docs = await getCardsWithLimit(
    [getActiveIntroQuery, getJobOffersQuery, getReadIntroQuery],
    PAGE_SIZE
  )
  if (!docs) return
  const {
    notificationHistory,
    notificationHistoryIds: { intro, jobOffer }
  } = normalizeNotificationHistory(docs, dispatch)
  const { INTRO, JOB_OFFER } = NotificationHistoryTypes
  dispatch(actionsNotifications.setHistory(notificationHistory, null))
  dispatch(actionsHome.setNotificationHistoryIds(intro, INTRO))
  dispatch(actionsHome.setNotificationHistoryIds(jobOffer, JOB_OFFER))
  dispatch(actionsNotifications.setIsLoadedHistory(true))
}

export const getHomeCards = async (
  uid: string, dispatch: (action: any) => void
) => {
  const q = query(
    collection(firestoreDb, `profiles/${uid}/notifications`),
    and(
      or(...jobOfferDocTypes.map((type) => where('type', '==', type))),
      where('status', '==', NotificationStatusTypes.ACTIVE)
    )
  )

  const querySnapshot = await getDocs(q).catch((e) => {
    console.error('Error getting notifications: ', e)
    return null
  })
  if (!querySnapshot) return

  const {
    notificationHistory,
    maxNotificationDate,
    notificationHistoryIds: { intro, jobOffer }
  } = normalizeNotificationHistory(querySnapshot.docs, dispatch)
  const { INTRO, JOB_OFFER } = NotificationHistoryTypes

  dispatch(actionsNotifications.setHistory(notificationHistory, maxNotificationDate))
  dispatch(actionsHome.setNotificationHistoryIds(intro, INTRO))
  dispatch(actionsHome.setNotificationHistoryIds(jobOffer, JOB_OFFER))
  dispatch(actionsNotifications.setIsLoadedHistory(true))
}

export const getClosedCards = async (
  uid: string, dispatch: (action: any) => void
) => {
  const q = query(
    collection(firestoreDb, `profiles/${uid}/notifications`),
    and(
      where('type', '==', NotificationTypes.CLOSED_VACANCY),
      where('uid', '==', uid)
    )
  )

  const querySnapshot = await getDocs(q).catch((e) => {
    console.error('Error getting notifications: ', e)
    return null
  })
  if (!querySnapshot) return

  const {
    notificationHistory,
    notificationHistoryIds: { jobOffer }
  } = normalizeNotificationHistory(querySnapshot.docs, dispatch)
  const { JOB_OFFER } = NotificationHistoryTypes
  dispatch(actionsNotifications.addHistoryItems(notificationHistory))
  dispatch(actionsHome.setArchivedNotificationHistoryIds(jobOffer, JOB_OFFER))
  dispatch(actionsNotifications.setIsLoadedArchivedHistory(true))
}

export const getHomeCardsOptimized = async (
  uid: string,
  dispatch: (action: any) => void
) => {
  await getHomeCardsFirstPage(uid, dispatch)
  getHomeCards(uid, dispatch)
  getClosedCards(uid, dispatch)
}

export const init = (): ThunkType<ProfileType> => async (dispatch) => {
  const deviceId = getDeviceID()
  const { token: fcmToken, isSWActive } = await getTokenFcm()

  if (fcmToken) {
    dispatch(actions.setIsActiveFcm(true))
  }

  dispatch(commonActions.setIsSWActivated(isSWActive))

  const device = {
    id: deviceId,
    os: window.navigator.appVersion,
    fcm_token: fcmToken,
    bundle: BUNDLE
  }

  const profile = await profileAPI.afterLogin(device).catch(() => {
    dispatch(actions.setMyProfile(null))
    return null
  })

  if (!profile) return null

  const updatedProfile = {
    ...profile,
    currentDeviceId: deviceId
  }
  dispatch(actionsHome.addBulkMemoizedUsers(profile.contacts))
  dispatch(actions.setMyProfile(updatedProfile))

  // if (fcm_token) {
  //   const messaging = getMessaging(firebaseApp)
  //
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload)
  //     dispatch(checkIncomingCall(payload))
  //   })
  // }
  await getHomeCardsOptimized(profile.uid, dispatch)
  return profile
}

export const getMyProfile = (isInitialLogin: boolean): ThunkType<string> => async (dispatch, getState) => {
  if (isInitialLogin) {
    const myUid = getState().profile.profile?.uid ?? ''
    return myUid
  }
  const docRef = doc(firestoreDb, 'profiles', getState().auth.uid)
  const profile = await getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        return doc.data() as (ProfileType & { contacts: { [uid: string]: ProfileType } })
      }
      return null
    })
    .catch(() => {
      dispatch(actions.setMyProfile(null))
      return null
    })
  if (!profile) return ''
  dispatch(actionsHome.addBulkMemoizedUsers(profile.contacts))
  dispatch(actions.setMyProfile(profile))
  await getHomeCardsOptimized(profile.uid, dispatch)
  return profile.uid
}

export const getTrustLevelsAsync = (level: number = 3): ThunkType => async (dispatch) => {
  const trustedUsers = await usersAPI.getTrustLevels(level).catch(() => null)
  if (!trustedUsers) return
  const trustUsers = trustedUsers.result.reduce((
    acc: { [key: string]: TrustedUserType }, { level, profile }: TrustedUserType
  ) => {
    if (level >= 1) {
      acc[profile.uid] = { level, profile }
    }
    return acc
  }, {})
  dispatch(actions.setTrustUsers(trustUsers))
}

export const getTrustLevels = (level: number = 3): ThunkType => async (dispatch) => {
  usersAPI.getTrustLevels(level)
    .then((trustedUsers) => {
      if (!trustedUsers) return
      const trustUsers = trustedUsers.result.reduce((
        acc: { [key: string]: TrustedUserType }, { level, profile }: TrustedUserType
      ) => {
        if (level >= 1) {
          acc[profile.uid] = { level, profile }
        }
        return acc
      }, {})
      dispatch(actions.setTrustUsers(trustUsers))
    })
    .catch(() => null)
}

export const getCommonTrustedContactList = (): ThunkType => async (dispatch, getState) => {
  const { profile: { commonTrustedContactList } } = getState()
  if (isEmpty(commonTrustedContactList)) {
    const response = await usersAPI.getCommonTrustedContactList().catch(() => null)
    if (!response?.uids) return
    dispatch(actions.setUidsTrustedByMyContacts(response.uids))
  }
}

export const getTrustedContactList = (uid: string, depth: number = 1)
  : ThunkType => async (dispatch) => {
  const response = await usersAPI.getTrustedContactList(uid, depth).catch(() => null)
  if (!response?.trust) return
  const profiles = response.trust.map((item: any) => item.profile)
  dispatch(actionsHome.setOtherUserTrusted(profiles, uid))
}

export const getMyVacancies = (resetProfileOnFailure = true): ThunkType => async (dispatch) => {
  const response = await profileAPI.getMyVacancies().catch(() => {
    if (resetProfileOnFailure) dispatch(actions.setMyProfile(null))
    return null
  })
  if (!response) {
    dispatch(actions.setMyVacanciesLoading(false))
  } else dispatch(actions.setMyVacancies(response?.vacancies || []))
}

export const addVacancy = (
  vacancy: EditProfileElementsTypes,
  translations: any,
  isListingPage: boolean,
  options?: { onFinish?: (isSuccess: boolean, limitReached: boolean) => void }
): ThunkType =>
  async (dispatch, getState) => {
    try {
      const result = await profileAPI.addVacancy(vacancy)
      if (result?.id) {
        const { profile: { profile } } = getState()
        const newVacancy = {
          ...vacancy,
          ts: `${dayjs().format(EXPLORE_CARD_TIME_FORMAT_BE)}Z`,
          id: result?.id,
          status: STATUS_VACANCIES.OPEN,
          employer: profile,
          uid: profile?.uid
        }
        dispatch(actions.addNewVacancy(newVacancy))
        options?.onFinish?.(true, false)
        dispatch(actionsToast.addToast({
          type: ToastType.SUCCESS,
          message: isListingPage
            ? translations.listingsHiringCreatedAlertMessage
            : translations.exploreHiringCreatedAlertMessage
        }))
      }
    } catch (error) {
      const { status } = error as { status: number }
      if (status === API_STATUS_CODES.BAD_REQUEST) {
        options?.onFinish?.(false, true)
        dispatch(getMyVacancies(false))
        dispatch(actionsToast.addToast({
          type: ToastType.WARNING,
          message: translations.vacancyLimitMessage
        }))
      } else {
        options?.onFinish?.(false, false)
        dispatch(actionsToast.addToast({
          type: ToastType.ERROR,
          message: translations.errorNewVacancy
        }))
      }
    }
  }

export const deleteMyVacancy = (
  vacancyId: string, translations: any, notificationId?: string, isArchivedCard?: boolean
): ThunkType => async (dispatch, getState) => {
  const { notifications } = getState()
  const notification = notifications.history[notificationId || '']

  if (notificationId) {
    dispatch(actionsNotifications.archivingNotification(notificationId))
    setTimeout(() => {
      if (isArchivedCard) {
        dispatch(actionsHome.deleteArchivedJobOfferNotification(notificationId))
        dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
        dispatch(actions.deleteVacancy(vacancyId))
      } else {
        dispatch(actionsHome.deleteJobOfferNotification(notificationId))
        dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
      }
      dispatch(actionsNotifications.removeArchivingNotification(notificationId))
    }, NOTIFICATION_HIDE_DELAY_MS)
  }

  try {
    await profileAPI.deleteVacancy(vacancyId)
  } catch (error) {
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorDeleteVacancy
    }))
    if (notificationId) {
      if (isArchivedCard) {
        dispatch(actionsHome.deleteArchivedJobOfferNotification(notificationId))
        dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
      } else {
        dispatch(actionsNotifications.addHistoryItems({ [notificationId]: notification }))
        dispatch(actionsHome.prependArchivedNotificationHistoryId(notificationId, NotificationHistoryTypes.JOB_OFFER))
      }
    }
  }
}

export const closeMyVacancy = (
  vacancyId: string, translations: any, notificationId?: string
): ThunkType => async (dispatch, getState) => {
  const { notifications } = getState()
  const notification = notifications.history[notificationId || '']
  if (notificationId) {
    dispatch(actionsNotifications.archivingNotification(notificationId))
  }
  setTimeout(() => {
    if (notificationId) {
      dispatch(actionsHome.deleteJobOfferNotification(notificationId))
      dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
      dispatch(actionsNotifications.removeArchivingNotification(notificationId))
    }
    dispatch(actions.closeVacancy(vacancyId))
  }, NOTIFICATION_HIDE_DELAY_MS)

  try {
    await profileAPI.closeVacancy(vacancyId)
  } catch (error) {
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorCloseVacancy
    }))
    if (notificationId) {
      dispatch(actionsNotifications.addItemInHistory(notificationId, notification))
      dispatch(actionsHome.prependNotificationHistoryId(notificationId, NotificationHistoryTypes.JOB_OFFER))
    }
  }
}

export const closeMySpecialty = (
  uid: string, specialityId: string, translations: any, notificationId?: string
): ThunkType => async (dispatch, getState) => {
  const { notifications } = getState()
  const notification = notifications.history[notificationId || '']
  if (notificationId) {
    dispatch(actionsNotifications.archivingNotification(notificationId))
  }
  setTimeout(() => {
    if (notificationId) {
      dispatch(actionsHome.deleteJobOfferNotification(notificationId))
      dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
      dispatch(actionsNotifications.removeArchivingNotification(notificationId))
    }
    dispatch(actions.closeSpecialty({ uid, specialityId }))
  }, NOTIFICATION_HIDE_DELAY_MS)

  try {
    await profileAPI.closeSpecialty(specialityId)
  } catch (error) {
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorCloseSpecialty
    }))
    if (notificationId) {
      dispatch(actionsNotifications.addItemInHistory(notificationId, notification))
      dispatch(actionsHome.prependNotificationHistoryId(notificationId, NotificationHistoryTypes.JOB_OFFER))
    }
  }
}

export const favorVacancy = (
  vacancyId: string, profile: ContactsType, onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  try {
    const response = await profileAPI.favorVacancy(vacancyId, profile.uid)
    const { chat: chatId } = response
    const { conversations: { chats } } = getState()
    const favor = { chat: chatId, employer: profile, vacancyId }
    dispatch(actions.setFavoredVacancy(favor))
    if (!chats?.[chatId]) await dispatch(addChat(chatId, [profile.uid]))
    dispatch(actionsConversations.setOpenedChat(chatId))
    onFinish?.()
  } catch (error) {
    const { status, error: errorMsg } = error as { status: number; error: string }
    dispatch(actionsNotifications.addErrorMsg({
      type: ErrorModalTypes.DEFAULT,
      description: errorMsg,
      status
    }))
  }
}

export const favorSpecialty = (
  specialtyId: string, profile: ContactsType, onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  try {
    const response = await profileAPI.favorSpecialty(specialtyId, profile.uid)
    const { chat: chatId } = response
    const { conversations: { chats } } = getState()
    const specialty = { chat: chatId, userUid: profile.uid, specialtyId }
    dispatch(actions.addFavoredSpecialty(specialty))
    if (!chats?.[chatId]) await dispatch(addChat(chatId, [profile.uid]))
    dispatch(actionsConversations.setOpenedChat(chatId))
    onFinish?.()
  } catch (error) {
    const { status, error: errorMsg } = error as { status: number; error: string }
    dispatch(actionsNotifications.addErrorMsg({
      type: ErrorModalTypes.DEFAULT,
      description: errorMsg,
      status
    }))
  }
}

export const favoredVacancies = (onFinish?: () => void): ThunkType => async (dispatch) => {
  const response = await profileAPI.getAllVacanciesFavors().catch(() => null) // TODD: Handle error message
  if (!response?.vacancy_responses?.length) return
  const favoredSpecialties = response?.vacancy_responses.map((vacancyFavor: VacancyOfferOrFavorType) => {
    const newFavoredSpecialties = { ...vacancyFavor, vacancyId: vacancyFavor.id, employerId: vacancyFavor.employer_id }
    delete newFavoredSpecialties?.id
    delete newFavoredSpecialties?.employer_id
    return newFavoredSpecialties
  })
  dispatch(actions.setFavoredVacancies(favoredSpecialties))
  onFinish?.()
}

export const favoredSpecialties = (onFinish?: () => void): ThunkType => async (dispatch) => {
  const response = await profileAPI.getSpecialityResponses().catch(() => null)
  const { resume_responses: specialtyResponses } = response || {}
  if (!specialtyResponses?.length) return
  const favoredSpecialties: SpecialtyOffer[] = specialtyResponses.map((specialtyFavor: SpecialtyOfferResponse) => {
    return {
      specialtyId: specialtyFavor.id,
      userUid: specialtyFavor.employee_id,
      chat: specialtyFavor.chat
    }
  })
  dispatch(actions.setFavoredSpecialties(favoredSpecialties))
  onFinish?.()
}

export const offeredVacancies = (onFinish?: () => void): ThunkType => async (dispatch) => {
  const response = await profileAPI.getAllOffers().catch(() => null) // TODD: Handle error message
  if (!response?.vacancy_offers?.length) return
  const offeredVacancies = response?.vacancy_offers.map((vacancyOffer: VacancyOfferOrFavorType) => {
    const newOfferedVacancies = { ...vacancyOffer, vacancyId: vacancyOffer.id, employeeId: vacancyOffer.uid }
    delete newOfferedVacancies?.id
    delete newOfferedVacancies?.uid
    return newOfferedVacancies
  })
  dispatch(actions.setOfferedVacancies(offeredVacancies))
  onFinish?.()
}

export const getSpecialties = (
  uid?: string,
  options = { withError: true } as { onFinish?: () => void, withError?: boolean }
): ThunkType => async (dispatch, getState) => {
  try {
    const myUid = getState().profile.profile?.uid ?? ''
    const response = !uid || myUid === uid
      ? (await profileAPI.getSpecialities())
      : (await profileAPI.getOtherSpecialities(uid))
    const userUid = !uid || myUid === uid ? myUid : uid
    dispatch(actions.setSpecialities({
      uid: userUid || response.resumes[0]?.uid,
      specialities: response.resumes || []
    }))
  } catch (error) {
    dispatch(actions.setSpecialities({ uid, specialities: [] }))
    if (options?.withError) {
      const { status } = error as { status: number }
      dispatch(actionsNotifications.addErrorMsg({ type: ErrorModalTypes.DEFAULT, status: status || 500 }))
    }
  } finally {
    options?.onFinish?.()
  }
}

export const addSpeciality = (
  uid: string,
  speciality: EditProfileElementsTypes,
  translations: any,
  isListingPage: boolean,
  options?: { onFinish?: (isSuccess: boolean, limitReached: boolean) => void }
): ThunkType => async (dispatch, getState) => {
  try {
    const { profile: { profile } } = getState()
    const result = await profileAPI.addSpeciality(speciality)
    if (result?.id) {
      const newSpeciality = {
        ...speciality,
        id: result.id,
        status: STATUS_VACANCIES.OPEN,
        ts: `${dayjs().format(EXPLORE_CARD_TIME_FORMAT_BE)}Z`,
        uid: profile?.uid,
        employee: profile
      }
      dispatch(actions.addNewSpeciality({ uid, speciality: newSpeciality }))
      options?.onFinish?.(true, false)
      dispatch(actionsToast.addToast({
        type: ToastType.SUCCESS,
        message: isListingPage
          ? translations.listingsOpenToCreatedAlertMessage
          : translations.exploreOpenToCreatedAlertMessage
      }))
    }
  } catch (error) {
    const { status } = error as { status: number }
    if (status === API_STATUS_CODES.BAD_REQUEST) {
      options?.onFinish?.(false, true)
      dispatch(getSpecialties())
      dispatch(actionsToast.addToast({
        type: ToastType.WARNING,
        message: translations.specialtyLimitMessage
      }))
    } else {
      options?.onFinish?.(false, false)
      dispatch(actionsToast.addToast({
        type: ToastType.ERROR,
        message: translations.errorNewSpecialty
      }))
    }
  }
}

export const editSpeciality = (
  uid: string,
  specialityId: string,
  speciality: EditProfileElementsTypes,
  status: string,
  translations: any,
  onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  try {
    const { profile: { profile } } = getState()
    const { data: { id } } = await profileAPI.editSpeciality(specialityId, speciality)
    dispatch(actions.editSpeciality({
      uid,
      speciality: {
        ...speciality,
        id: specialityId || id,
        status,
        employee: profile,
        uid: profile?.uid
      }
    }))
    dispatch(actionsToast.addToast({
      type: ToastType.SUCCESS,
      message: translations.specialtyEditedSuccessMessage
    }))
  } catch (error) {
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorEditSpecialty
    }))
  } finally {
    onFinish?.()
  }
}

export const deleteSpeciality = (
  uid: string, specialityId: string, translations: any, notificationId?: string
): ThunkType => async (dispatch, getState) => {
  const { notifications } = getState()
  const notification = notifications.history[notificationId || '']
  if (notificationId) {
    dispatch(actionsNotifications.archivingNotification(notificationId))
  }
  setTimeout(() => {
    if (notificationId) {
      dispatch(actionsHome.deleteJobOfferNotification(notificationId))
      dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
      dispatch(actionsNotifications.removeArchivingNotification(notificationId))
    }
    dispatch(actions.deleteSpeciality({ uid, specialityId }))
  }, NOTIFICATION_HIDE_DELAY_MS)

  try {
    await profileAPI.deleteSpeciality(specialityId)
  } catch (error) {
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorDeleteSpecialty
    }))
    if (notificationId) {
      dispatch(actionsNotifications.addItemInHistory(notificationId, notification))
      dispatch(actionsHome.prependNotificationHistoryId(notificationId, NotificationHistoryTypes.JOB_OFFER))
    }
  }
}

export const onReceiveNotifications =
  (
    uid: string,
    dispatch: (action: any) => void | Promise<IncomingCallType>,
    room: Room | null,
    maxNotificationDate: string
  ) => {
    const q = query(
      collection(firestoreDb, `profiles/${uid}/notifications`),
      and(
        where('ts', '>', maxNotificationDate),
        where('status', '!=', NotificationStatusTypes.HIDDEN)
      )
    )

    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const doc = change.doc.data() as ValueNotificationsHistoryType
        if (
          doc.status === NotificationStatusTypes.ARCHIVED
        ) {
          if (doc.type === NotificationTypes.RESUME) {
            dispatch(deleteByJobOfferId(doc.data.id))
          } else if (
            doc.type !== NotificationTypes.MODIFIED_RESUME
            && doc.type !== NotificationTypes.MODIFIED_VACANCY
            && doc.type !== NotificationTypes.NEW_RESUME
            && doc.type !== NotificationTypes.NEW_VACANCY
          ) {
            dispatch(deleteNotificationById(change.doc.id, doc))
          }
          return
        }

        if (change.type === 'added') {
          if (doc.type === NotificationTypes.CALL_CANCELED) cancelMeeting(doc.contact, doc.data.start_time)

          switch (doc.type) {
            case NotificationTypes.CALL_DECLINED: {
              clearVideoChat(room, dispatch)
              break
            }
            case NotificationTypes.CALL_FINISHED: {
              callFinishedNotification(room, doc.contact)
              break
            }
            case NotificationTypes.MY_INTRO: {
              dispatch(actionsNotifications.addItemInHistory(change.doc.id, { ...doc, id: change.doc.id }))
              dispatch(addChat(doc.data.chat, [doc.data.contacts[0].uid, doc.data.contacts[1].uid]))
              break
            }
            case NotificationTypes.INTRO: {
              if (!doc.data?.broker || !doc.data?.contacts?.length) break
              const normalizedNotification = normalizeIntroNotification(doc, change.doc.id)
              const otherContact = normalizedNotification.data.contacts.find(
                (contact: ContactsType) => contact.uid !== uid
              )
              dispatch(actionsNotifications.addItemInHistory(change.doc.id, normalizedNotification))
              dispatch(actionsHome.prependNotificationHistoryId(change.doc.id, NotificationHistoryTypes.INTRO))
              dispatch(addChat(
                normalizedNotification.data.chat,
                [otherContact?.uid, normalizedNotification.data.broker.uid]
              ))
              break
            }
            case NotificationTypes.NEW_CHAT:
            case NotificationTypes.FAVOR_VACANCY:
            case NotificationTypes.OFFER_VACANCY: {
              dispatch(actionsNotifications.addItemInHistory(change.doc.id, { ...doc, id: change.doc.id }))
              if (doc.uid !== doc.contact) dispatch(addChat(doc.data.chat, [doc.contact]))
              break
            }
            case NotificationTypes.DELETE_RESUME:
            case NotificationTypes.DELETE_VACANCY: {
              dispatch(deleteJobOfferById(doc.data.id || doc.data.resume_id || doc.data.vacancy_id || ''))
              profileAPI.archiveNotifications([change.doc.id])
              break
            }
            case NotificationTypes.CLOSED_RESUME:
            case NotificationTypes.CLOSED_VACANCY: {
              dispatch(deleteJobOfferById(doc.data.id || doc.data.vacancy_id || doc.data.resume_id || ''))
              const normalized = normalizeJobOfferNotification(change.doc.id, doc)
              if (normalized) {
                dispatch(actionsHome.prependArchivedNotificationHistoryId(
                  change.doc.id, NotificationHistoryTypes.JOB_OFFER
                ))
                dispatch(actionsNotifications.addHistoryItems({ [change.doc.id]: normalized }))
              }
              break
            }
            case NotificationTypes.MODIFIED_VACANCY:
            case NotificationTypes.MODIFIED_RESUME:
            case NotificationTypes.NEW_VACANCY:
            case NotificationTypes.NEW_RESUME:
            case NotificationTypes.RESUME:
            case NotificationTypes.VACANCY: {
              dispatch(addOrUpdateNotificationHistoryJobOffer(change.doc.id, doc))
              break
            }
            case NotificationTypes.CONTACT_DELETED: {
              dispatch(deleteNotificationsByUserId(doc.contact))
              break
            }
            case NotificationTypes.CALL_CANCELED: {
              clearVideoChat(room, dispatch)
              break
            }
            case NotificationTypes.CALL_INSTANT: {
              if (envConfig.features?.callEnabled) {
                const { contact, data: { room, token } } = doc
                const user = await dispatch(addMemoizedUser(contact, true, {}))
                if (user) {
                  const { photoUrl, photo, name } = user
                  const payload = {
                    uid: contact,
                    name,
                    photoUrl,
                    photo,
                    room,
                    token
                  }
                  dispatch(actionsNotifications.addIncomingCall(payload))
                }
              }
              break
            }
            default: break
          }

          if (doc.type === 'twilio_enter_group') {
            const { data: { room, token } } = doc
            dispatch(connectToVideoRoom(room, token, true))
          }
        }
      })
    })
  }

export const updateUsers = (contactsUids: string[]) => (
  dispatch: (action: any) => void, getState: () => AppStateType
) => {
  const {
    profile: {
      profile
    }
  } = getState()
  const prevContacts = profile?.contacts || []
  if (prevContacts.length === contactsUids.length) return
  const deletedContactUid = prevContacts.find((uid) => !contactsUids.includes(uid))
  if (deletedContactUid) {
    dispatch(actionsHome.addDeletedUser(deletedContactUid))
    dispatch(actions.deleteContact(deletedContactUid))
    return
  }
  const newContactUids = contactsUids.filter((uid) => !prevContacts.includes(uid))
  dispatch(actions.addContacts(newContactUids))
}

export const onProfileUpdate = (uid: string, dispatch: (action: any) => void) => {
  return onSnapshot(doc(firestoreDb, 'profiles', uid), (doc) => {
    const profileData = doc.data()
    if (!profileData) return
    const contactsUids = Object.keys(profileData.contacts || {})
    const trustedUids = profileData.trust || []
    dispatch(updateUsers(contactsUids))
    dispatch(updateTrustedUsers(trustedUids))
    dispatch(actionsHome.addBulkMemoizedUsers(profileData.contacts))
  })
}

export const updateTrustedUsers = (trustedUids: string[]) => (
  dispatch: (action: any) => void, getState: () => AppStateType
) => {
  const { profile: { profile } } = getState()
  const previousTrustedUids = profile?.trust
  if (trustedUids?.sort().join() !== previousTrustedUids?.sort().join()) {
    dispatch(actions.setTrustedUids(trustedUids))
    dispatch(getTrustLevels())
  }
}

export const addContact = (
  profileUid: string,
  isExistingContact: boolean
): ThunkType => async (dispatch, getState) => {
  if (!isExistingContact) {
    try {
      await usersAPI.addContact(profileUid)
      const existingContacts = getState().profile.profile?.contacts || []
      if (!existingContacts.includes(profileUid)) {
        dispatch(actions.addContact(profileUid))
      }
    } catch (error: any) {
      const { status } = error as { status: number }
      if (status === apiCodes.error) {
        dispatch(actionsNotifications.addErrorMsg({ type: ErrorModalTypes.CUSTOM, description: 'Failed to add the contact' }))
      } else {
        dispatch(actionsNotifications.addErrorMsg({ type: ErrorModalTypes.DEFAULT, status: status || 500 }))
      }
    }
  }
}

export const showNotification =
  (result: ResultCompareContactsType | ResultCompareInstanceCallType | 'declinedCall'): ThunkType =>
    async (dispatch, getState) => {
      const { profile } = getState().profile

      if (profile) {
        if (result === 'declinedCall') {
          const { room } = getState().videoChat
          if (room) {
            room.disconnect()
            dispatch(actionsVideoChat.setRoom(null))
          }
        }
      }
    }

export const updateMyProfileSettings = (
  settings: any,
  onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  const { profile: { profile } } = getState()
  if (!profile) return
  const status = await profileAPI.updateSettings({ settings: settings.settings }).catch(() => apiCodes.error)
  if (status === apiCodes.success) {
    dispatch(actions.setMyProfile({ ...profile, settings }))
  }
  onFinish?.()
}

export const hideNotification = (
  notificationId: string, translation: any, specialityId?: string, notificationIdOld?: string
): ThunkType => async (dispatch, getState) => {
  const { notifications } = getState()
  const notification = notifications.history[notificationIdOld || ''] || notifications.history[notificationId]
  let errored = false
  profileAPI.hideNotifications([notificationId]).catch(() => {
    errored = true
    dispatch(actionsNotifications.addItemInHistory(notificationIdOld || notificationId, notification))
    dispatch(
      actionsHome.prependNotificationHistoryId(
        notificationIdOld || notificationId, NotificationHistoryTypes.JOB_OFFER
      )
    )
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: specialityId ? translation.errorHideSpecialty : translation.errorHideVacancy
    }))
  })
  dispatch(actionsNotifications.hidingNotification(notificationId))
  setTimeout(() => {
    dispatch(actionsNotifications.removeHidingNotification(notificationId))
    if (errored) return
    dispatch(actionsHome.deleteJobOfferNotification(notificationId))
    if (notificationIdOld) dispatch(actionsHome.deleteJobOfferNotification(notificationIdOld))
    dispatch(actionsNotifications.deleteItemFromHistory(notificationId))
    if (notificationIdOld) dispatch(actionsNotifications.deleteItemFromHistory(notificationIdOld))
  }, NOTIFICATION_HIDE_DELAY_MS)
}

export const updateMyProfile = (
  body: { [key: string]: any },
  onFinish?: (error?: string) => void,
  revertOnFailure = false
): ThunkType => async (dispatch, getState) => {
  const { profile } = getState().profile
  try {
    if (revertOnFailure) dispatch(actions.setMyProfile(cloneDeep({ ...profile, ...body })))
    const status = await profileAPI.updateMyProfile(body)
    if (status === apiCodes.success) {
      dispatch(actions.setMyProfile({ ...profile, ...body }))
      onFinish?.()
    } else {
      onFinish?.(status as any)
    }
  } catch (error: any) {
    onFinish?.(error?.error || '')
    if (revertOnFailure) {
      dispatch(actions.setMyProfile(cloneDeep(profile)))
    }
    dispatch(actionsNotifications.addErrorMsg(
      { type: ErrorModalTypes.DEFAULT, status: error.status || 500 }
    ))
  }
}

export const checkUsernameUnique = (
  username: string,
  onFinish?: (success: boolean) => void
): ThunkType => async () => {
  try {
    const status = await profileAPI.checkUsernameUnique(username)
    if (status === apiCodes.success) {
      await onFinish?.(true)
    } else {
      await onFinish?.(false)
    }
  } catch (error) {
    await onFinish?.(false)
  }
}

export const editVacancy = (
  vacancyId: string,
  vacancy: EditProfileElementsTypes,
  status: string,
  translations: any,
  onFinish?: () => void
): ThunkType => async (dispatch, getState) => {
  try {
    const { profile: { profile } } = getState()
    await profileAPI.editVacancy(vacancyId, vacancy)
    const updatedVacancy = {
      ...vacancy,
      id: vacancyId,
      status,
      employer: profile,
      uid: profile?.uid
    }
    dispatch(actions.editVacancy(updatedVacancy))
    onFinish?.()
    dispatch(actionsToast.addToast({
      type: ToastType.SUCCESS,
      message: translations.vacancyEditedSuccessMessage
    }))
  } catch (error) {
    onFinish?.()
    dispatch(actionsToast.addToast({
      type: ToastType.ERROR,
      message: translations.errorEditVacancy
    }))
  }
}

export const callNow = (uid: string): ThunkType => async (dispatch, getState) => {
  const { profile } = getState().profile
  const deviceId = getDeviceID()
  if (profile) {
    dispatch(actionsVideoChat.setViewEndCallAll(true))
    dispatch(actionsVideoChat.setIsMyProfileIsOwnerOutgoingCall())

    const response: ResponseCallNowType = await usersAPI.callNow(uid, deviceId).catch((err) => {
      const { status, error: errorMsg } = err as { status: number; error: string }
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: errorMsg,
        status
      }))
    })

    if (response) {
      const { room, token } = response
      dispatch(connectToVideoRoom(room, token))
      dispatch(actions.setMyProfile({ ...profile, slots: { ...profile.slots, now: { uid } } }))
    }
  }
}

export const callLeave = (): ThunkType => async (dispatch, getState) => {
  const { profile } = getState().profile
  if (profile) {
    const uid = profile?.slots?.now?.uid as string
    if (uid) {
      await usersAPI.callLeave(uid).catch((err) => {
        const { status, error: errorMsg } = err as { status: number; error: string }
        dispatch(actionsNotifications.addErrorMsg({
          type: ErrorModalTypes.DEFAULT,
          description: errorMsg,
          status
        }))
      })
      actions.updateMySlots(EnumTimeSlots.DELETE, 'now')
    }
  }
}

export const declineCall = (uid: string): ThunkType => async (dispatch, getState) => {
  const { room } = getState().videoChat

  dispatch(actions.updateMySlots(EnumTimeSlots.DELETE, 'now'))

  const status = await usersAPI.callDecline(uid)

  if (status === apiCodes.success) {
    room?.disconnect()
    dispatch(actionsVideoChat.reset())
  }
}

export const connectToCall = (date: string, uid: string): ThunkType => async (dispatch, getState) => {
  const {
    profile: { profile },
    surf: { memoizedUsers }
  } = getState()

  if (profile) {
    const companion = memoizedUsers?.[uid]

    const timeZone = dayjs(new Date()).utcOffset()
    const formattedDate = `${dayjs(date).subtract(timeZone, 'minutes').format('YYYY-MM-DDTHH:mm:00')}Z`

    const res = await usersAPI.connectToCall(formattedDate, uid).catch((err) => {
      const { status, error: errorMsg } = err as { status: number; error: string }
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: errorMsg,
        status
      }))
    })

    if (res?.data.status === 'scheduled') {
      const newSlot = {
        status: 'scheduled',
        duration: 15,
        uid
      }
      const attributes = { ...res.data, scheduledAt: date, duration: res.data.duration * 60 }
      if (companion?.chat) {
        dispatch(sendMessage('Meeting', companion.chat, attributes))
      } else {
        const { chat_sid: chatSid }: { chat_sid: string, status: string } = await usersAPI
          .createChat(uid)
          .catch((err) => {
            const { status, error: errorMsg } = err as { status: number; error: string }
            dispatch(actionsNotifications.addErrorMsg({
              type: ErrorModalTypes.DEFAULT,
              description: errorMsg,
              status
            }))
          })
        dispatch(sendMessage('Meeting', chatSid, attributes))
      }
      dispatch(actions.updateMySlots(EnumTimeSlots.ADD, { [formattedDate]: newSlot }))
      // dispatch(actionsNotifications.addAnyMsg({
      //   msg: `You have scheduled a meeting with ${companionName}`,
      //   uid: uuidv4()
      // }))
    }
  }
}

export const sendCallSummary = (roomId: string): ThunkType => async (dispatch, getState) => {
  const { profile: { profile }, conversations: { chats } } = getState()
  const callsHistory = await usersAPI.getCallHistory()
  const room = callsHistory.rooms[roomId]
  if (!room) return
  const eventIndexes = Object.keys(room).filter((index) => isNumber(index))
  const callStartTime = room[eventIndexes[0]].Timestamp
  const callEndTime = room[eventIndexes[eventIndexes.length - 1]].Timestamp
  const callDuration = dayjs(callEndTime).diff(callStartTime, 'seconds')
  const remoteUserUid = room.users.find((id: string) => id !== profile?.uid)
  const user = Object.values(chats).find((chat) => chat?.remoteParticipants[0].uid === remoteUserUid)
  if (user && user?.conversation?.sid) {
    dispatch(sendMessage('', user.conversation.sid, { duration: room.participants[remoteUserUid] ? callDuration : 0 }))
  }
}

export const updateProfilePhoto = (imageUrl: string): ThunkType => async (dispatch) => {
  try {
    const response = await profileAPI.updateProfilePhoto(dataURLtoFile(imageUrl))
    dispatch(actions.updateMyProfilePhoto(response.photoURL, response.photo))
  } catch (err: any) {
    dispatch(actionsNotifications.addErrorMsg({
      type: ErrorModalTypes.DEFAULT,
      status: Number(err?.code) || 501
    }))
  }
}

import { FC } from 'react'
import {
  UseFormClearErrors,
  UseFormReturn,
  UseFormSetError
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  CURRENCIES,
  LENGTH_LIMITS,
  LOCALISE_SEPARATORS,
  REGEX,
  SYMBOLS,
  VACANCY_MODAL_WIDTH
} from 'common/constants'
import { FIELDS, INPUT_TYPES } from 'common/enums'
import { useAudiences } from 'common/hooks/useAudiences'
import { usePayPeriods } from 'common/hooks/usePayPeriods'
import { useTypeOfJobs } from 'common/hooks/useTypeOfJobs'
import { areStringsEqual } from 'common/utils/areStringsEqual'
import { joinLocations } from 'common/utils/profile'
import envConfig from 'config'
import { LocationField } from 'features/FormInput_V2/LocationField'
import { TagsInputField } from 'features/FormInput_V2/TagsInputField'
import { selectAllLocations } from 'features/Locations/selectors'
import { EditLinks } from 'features/MyProfile/components/EditLinks'
import { JobTitleField } from 'features/MyProfile/components/VacancySpeciality_V2/JobTitleField'
import { LocationListField } from 'features/MyProfile/components/VacancySpeciality_V2/LocationListField'
import { SocialLinkType } from 'features/MyProfile/types'
import {
  selectErrorMsgsTranslations, selectOnboardingTranslations, selectProfileTranslations, selectVacanciesTranslations
} from 'features/Translations/selectors'

export interface ILocationType {
  remote: boolean;
  onsite: boolean;
  hybrid: boolean;
}

export interface IVacancySpecialityForm {
  [FIELDS.JOB_NAME]: string,
  [FIELDS.LOCATIONS]: string[],
  [FIELDS.JOB_TYPE]: string,
  [FIELDS.ABOUT_JOB]: string,
  [FIELDS.TAGS]: string[],
  [FIELDS.LOCATION_TYPE]: ILocationType,
  [FIELDS.CURRENCY]: string,
  [FIELDS.SALARY_MAX]: number | null,
  [FIELDS.SALARY_MIN]: number | null,
  [FIELDS.RATE]: string,
  [FIELDS.AUDIENCE]: string,
  [FIELDS.PAYMENT]?: string,
  [FIELDS.URL]: { url: string }[]
}

// FIELDS sequence should be as same as the SECTIONS

export type VacancyFieldsConfig = {
  titleKey?: string,
  placeholder?: string,
  fieldName?: string,
  errorMsgKey?: string,
  height?: number,
  width?: number,
  isRequired?: boolean,
  helperText?: string,
  type?: INPUT_TYPES,
  customComponent?: FC<any>,
  maxCharCount?: any,
  minCharCount?: any,
  showMaxCharCount?: boolean,
  allVersions?: any,
  defaultValue?: any,
  links?: SocialLinkType[],
  allowMultiple?: boolean
  isSelectBox?: boolean,
  errorMsgVariable?: string,
  fieldErrorNames?: string[],
  groupKey?: FIELDS,
  numberOnly?: boolean,
  isBorderRed?: boolean,
  fields?: (VacancyFieldsConfig & {
    fieldName: FIELDS, titleKey: string, placeholder?: string, isBorderRed?: boolean, fieldErrorNames?: string[]
  })[]
  customErrorMsgKey?: FIELDS,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  isSubmitted?: boolean,
  errorMessage?: string
}

export const useVacancyForm = ({
  setError,
  clearErrors,
  trigger,
  formState: { errors }
}: Pick<
  UseFormReturn<IVacancySpecialityForm>,
  'getValues' | 'setError' | 'clearErrors' | 'trigger' | 'formState'
>) => {
  const profileTranslations = useSelector(selectProfileTranslations)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)

  const payPeriods = usePayPeriods()
  const typeOfJobs = useTypeOfJobs()
  const audiences = useAudiences()

  const SECTIONS = (
    values: any,
    shouldRevalidate: boolean,
    isOnEditing: boolean,
    sectionValues: any = {}
  ): VacancyFieldsConfig[] => {
    const isNumberButNotZero = (number: number) => typeof number === 'number' && isFinite(number) && number > 0
    const salaryMinOrMax =
      isNumberButNotZero(values[FIELDS.SALARY_MIN]) ||
      isNumberButNotZero(values[FIELDS.SALARY_MAX])
    const salaryRangeError = values[FIELDS.SALARY_MAX]
      && values[FIELDS.SALARY_MIN]
      && values[FIELDS.SALARY_MIN] > values[FIELDS.SALARY_MAX]
    const salaryError = salaryMinOrMax
      ? [
        values[FIELDS.CURRENCY],
        values[FIELDS.SALARY_MIN],
        values[FIELDS.SALARY_MAX],
        values[FIELDS.RATE]
      ].reduce(
        (acc, text) => (!text ? `${acc}${acc ? ' ' : ''}fieldName` : acc),
        ''
      )
      : ''

    const isLocationRequired =
      values[FIELDS.LOCATION_TYPE]?.hybrid === true ||
      values[FIELDS.LOCATION_TYPE]?.onsite === true

    const salaryMinErrorMsgKey = (!values[FIELDS.SALARY_MIN] && !!values[FIELDS.SALARY_MAX])
      ? 'errorMessageSalaryFrom'
      : ''
    // eslint-disable-next-line no-nested-ternary
    const salaryMaxErrorMsgKey = (!!values[FIELDS.SALARY_MIN] && !values[FIELDS.SALARY_MAX])
      ? 'errorMessageSalaryTo'
      : salaryRangeError ? 'wrongSalaryRange' : ''

    return [
      {
        titleKey: sectionValues[FIELDS.JOB_NAME]?.titleKey ?? 'jobTitle',
        placeholder: `${profileTranslations[sectionValues[FIELDS.JOB_NAME]?.titleKey ?? 'jobTitle']}*`,
        fieldName: FIELDS.JOB_NAME,
        isRequired: true,
        customComponent: JobTitleField,
        defaultValue: values[FIELDS.JOB_NAME],
        maxCharCount: LENGTH_LIMITS.MAX.JOB_TITLE
      },
      {
        titleKey: sectionValues[FIELDS.JOB_TYPE]?.titleKey ?? 'jobTypeTitle',
        placeholder: `${profileTranslations[sectionValues[FIELDS.JOB_TYPE]?.titleKey ?? 'jobTypeTitle']}*`,
        fieldName: FIELDS.JOB_TYPE,
        type: INPUT_TYPES.EXPENDABLE_INPUT,
        allVersions: typeOfJobs,
        isSelectBox: true,
        isRequired: true,
        defaultValue: { key: values[FIELDS.JOB_TYPE], label: vacanciesTranslations[values[FIELDS.JOB_TYPE]] },
        onBlur: () => shouldRevalidate && trigger(FIELDS.JOB_TYPE),
        errorMsgKey: sectionValues[FIELDS.JOB_TYPE]?.titleKey ?? 'jobTypeErrorText'
      },
      {
        titleKey: sectionValues[FIELDS.LOCATION_TYPE]?.titleKey ?? 'jobLocationTypeTitle',
        fieldName: FIELDS.LOCATION_TYPE,
        customComponent: LocationListField,
        isRequired: true,
        errorMsgKey: sectionValues[FIELDS.LOCATION_TYPE]?.titleKey ?? 'jobLocationTypeTitle'
      },
      {
        titleKey: sectionValues[FIELDS.LOCATIONS]?.titleKey ?? 'locationTitle',
        placeholder:
          `${profileTranslations[sectionValues[FIELDS.LOCATIONS]?.titleKey ?? 'locationTitle']}${isLocationRequired ? '*' : ''} `,
        fieldName: FIELDS.LOCATIONS,
        maxCharCount: LENGTH_LIMITS.MAX.LOCATION,
        customComponent: LocationField,
        isRequired: isLocationRequired,
        helperText: errorMsgsTranslations.errorMsgLocationsLimit
      },
      {
        titleKey: sectionValues[FIELDS.ABOUT_JOB]?.[isOnEditing ? 'titleKey' : 'createTitleKey'] ?? 'aboutJob',
        placeholder: `${profileTranslations[sectionValues[FIELDS.ABOUT_JOB]?.[isOnEditing ? 'titleKey' : 'createTitleKey'] ?? 'aboutJob']}*`,
        fieldName: FIELDS.ABOUT_JOB,
        type: INPUT_TYPES.TEXTAREA,
        height: 60,
        defaultValue: values[FIELDS.ABOUT_JOB],
        onBlur: () => shouldRevalidate && trigger(FIELDS.ABOUT_JOB),
        isRequired: true,
        helperText: `${profileTranslations[sectionValues[FIELDS.ABOUT_JOB]?.[isOnEditing ? 'helperText' : 'createHelperText']]}`
          ?? errorMsgsTranslations.errorMsgDescriptionLimit,
        errorMsgKey: sectionValues[FIELDS.ABOUT_JOB]?.[isOnEditing ? 'errorMsgKey' : 'createErrorMsgKey'],
        maxCharCount: LENGTH_LIMITS.MAX.ABOUT_JOB,
        minCharCount: LENGTH_LIMITS.MIN.ABOUT_JOB,
        showMaxCharCount: true
      },
      {
        titleKey: sectionValues[FIELDS.TAGS]?.titleKey ?? 'tags',
        placeholder: `${profileTranslations[sectionValues[FIELDS.TAGS]?.titleKey ?? 'tags']}`,
        fieldName: FIELDS.TAGS,
        customComponent: TagsInputField,
        maxCharCount: LENGTH_LIMITS.MAX.TAG_LENGTH,
        helperText: errorMsgsTranslations.tagsLimitErrorMsg
      },
      {
        errorMsgKey: salaryMinOrMax ? 'paymentError' : '',
        errorMsgVariable: salaryError,
        groupKey: FIELDS.PAYMENT,
        fields: [
          {
            titleKey: sectionValues[FIELDS.RATE]?.titleKey ?? FIELDS.RATE,
            fieldName: FIELDS.RATE,
            type: INPUT_TYPES.EXPENDABLE_INPUT,
            allVersions: payPeriods,
            isSelectBox: true,
            defaultValue: { key: values[FIELDS.RATE], label: onboardingTranslations[values[FIELDS.RATE]] },
            isRequired: false,
            errorMsgKey: (salaryMinOrMax) ? 'rateError' : '',
            placeholder: `${profileTranslations[sectionValues[FIELDS.RATE]?.titleKey ?? FIELDS.RATE]}`,
            errorMessage: errors[FIELDS.SALARY_MAX || FIELDS.SALARY_MIN]?.message ? '' : errors[FIELDS.RATE]?.message,
            onBlur: (e) => {
              if (shouldRevalidate) {
                validateSalary(
                  { ...values, [FIELDS.RATE]: e.target.value },
                  setError,
                  clearErrors,
                  profileTranslations,
                  trigger
                )
              }
            }
          },
          {
            titleKey: sectionValues[FIELDS.SALARY_MIN]?.titleKey ?? FIELDS.SALARY_MIN,
            placeholder: `${profileTranslations[sectionValues[FIELDS.SALARY_MIN]?.titleKey ?? FIELDS.SALARY_MIN]}`,
            defaultValue: values[FIELDS.SALARY_MIN],
            fieldName: FIELDS.SALARY_MIN,
            type: INPUT_TYPES.INPUT,
            numberOnly: true,
            isRequired: false,
            errorMsgKey: salaryMinErrorMsgKey,
            isBorderRed: (
              errors[FIELDS.SALARY_MIN]
              && !values[FIELDS.SALARY_MIN]
              && values[FIELDS.SALARY_MAX]
            ) || (
              errors[FIELDS.SALARY_MAX]
                && salaryRangeError
            ),
            onBlur: (e) => {
              if (shouldRevalidate) {
                validateSalary(
                  { ...values, [FIELDS.SALARY_MIN]: e.target.value },
                  setError,
                  clearErrors,
                  profileTranslations,
                  trigger
                )
              }
            },
            fieldErrorNames: [FIELDS.SALARY_MIN],
            onFocus: (e) => {
              if (shouldRevalidate) {
                validateSalary(
                  { ...values, [FIELDS.SALARY_MIN]: e.target.value },
                  setError,
                  clearErrors,
                  profileTranslations,
                  trigger
                )
                if (Number(values[FIELDS.SALARY_MIN]) && e.target.value) clearErrors(FIELDS.SALARY_MIN)
              }
            },
            width: VACANCY_MODAL_WIDTH
          },
          {
            titleKey: sectionValues[FIELDS.SALARY_MAX]?.titleKey ?? FIELDS.SALARY_MAX,
            placeholder: `${profileTranslations[sectionValues[FIELDS.SALARY_MAX]?.titleKey ?? FIELDS.SALARY_MAX]}`,
            defaultValue: values[FIELDS.SALARY_MAX],
            fieldName: FIELDS.SALARY_MAX,
            type: INPUT_TYPES.INPUT,
            errorMsgKey: salaryMaxErrorMsgKey,
            numberOnly: true,
            isRequired: false,
            onBlur: (e) => {
              if (shouldRevalidate) {
                validateSalary(
                  { ...values, [FIELDS.SALARY_MAX]: e.target.value },
                  setError,
                  clearErrors,
                  profileTranslations,
                  trigger
                )
              }
            },
            fieldErrorNames: [FIELDS.SALARY_MAX],
            onFocus: (e) => {
              if (shouldRevalidate) {
                validateSalary(
                  { ...values, [FIELDS.SALARY_MAX]: e.target.value },
                  setError,
                  clearErrors,
                  profileTranslations,
                  trigger
                )
                if (Number(values[FIELDS.SALARY_MAX]) && e.target.value) clearErrors(FIELDS.SALARY_MAX)
              }
            },
            width: VACANCY_MODAL_WIDTH
          },
          {
            titleKey: sectionValues[FIELDS.CURRENCY]?.titleKey ?? 'currency',
            placeholder: `${profileTranslations[sectionValues[FIELDS.CURRENCY]?.titleKey ?? 'currency']}`,
            fieldName: FIELDS.CURRENCY,
            type: INPUT_TYPES.EXPENDABLE_INPUT,
            allVersions: CURRENCIES,
            isRequired: false,
            defaultValue: values[FIELDS.CURRENCY],
            errorMsgKey: salaryMinOrMax ? 'helperCurrency' : '',
            errorMessage: shouldRevalidate && salaryMinOrMax && !values[FIELDS.CURRENCY] ? profileTranslations.helperCurrency : '',
            isSelectBox: true
          }
        ]
      },
      {
        titleKey: sectionValues[FIELDS.URL]?.titleKey ?? 'createVacancyEmptyUrlTitle',
        placeholder: `${profileTranslations[sectionValues[FIELDS.URL]?.titleKey ?? 'createVacancyEmptyUrlTitle']}`,
        fieldName: FIELDS.URL,
        customComponent: EditLinks,
        links: values[FIELDS.URL],
        defaultValue: values[FIELDS.URL],
        isRequired: false
      },
      ...(envConfig.features?.jobAudienceEnabled ? [{
        titleKey: sectionValues[FIELDS.AUDIENCE]?.titleKey ?? 'jobAudienceTitle',
        placeholder: `${profileTranslations[sectionValues[FIELDS.AUDIENCE]?.titleKey ?? 'jobAudienceTitle']}`,
        fieldName: FIELDS.AUDIENCE,
        type: INPUT_TYPES.EXPENDABLE_INPUT,
        allVersions: audiences,
        isSelectBox: true,
        defaultValue: { key: values[FIELDS.AUDIENCE], label: vacanciesTranslations[values[FIELDS.AUDIENCE]] }
      }] : [])
    ]
  }

  return { SECTIONS }
}

export const validateSalary = (
  fields: Partial<IVacancySpecialityForm>,
  setError: UseFormSetError<IVacancySpecialityForm>,
  clearErrors: UseFormClearErrors<IVacancySpecialityForm>,
  translations: { [key: string]: string },
  trigger?: any
) => {
  const stringToNumber = (element: any) => +element
  if (Number(!stringToNumber(fields[FIELDS.SALARY_MIN])) + Number(!stringToNumber(fields[FIELDS.SALARY_MAX])) === 1) {
    const { [FIELDS.SALARY_MIN]: salaryMin, [FIELDS.SALARY_MAX]: salaryMax, [FIELDS.RATE]: payPeriod } = fields
    const translationFields = { salaryMin, salaryMax, payPeriod }
    const missingFields = Object
      .entries(translationFields)
      .reduce((acc, [key, value]) => {
        return (!value || value === '0' ? `${acc}${acc ? ' ' : ''}${translations[key]}` : acc)
      }, '')
      .replace(REGEX.LAST_SPACE, ` ${translations.textAnd} `).toLowerCase()
    const message = translations.salaryPartiallyFilledError.replace(LOCALISE_SEPARATORS.DIGIT, missingFields)

    if (salaryMin) {
      setError(
        FIELDS.SALARY_MAX,
        { type: 'custom', message: translations.errorMessageSalaryTo }
      )
    } else {
      setError(
        FIELDS.SALARY_MIN,
        { type: 'custom', message: translations.errorMessageSalaryFrom }
      )
    }

    trigger([FIELDS.RATE, FIELDS.CURRENCY])
    return message
  }
  if ((fields[FIELDS.SALARY_MIN] || 0) > (fields[FIELDS.SALARY_MAX] || 0)) {
    const message = translations.wrongSalaryRange
    setError(
      FIELDS.SALARY_MAX,
      { type: 'custom', message }
    )
    trigger([FIELDS.RATE, FIELDS.CURRENCY])
    return message
  }

  if (fields[FIELDS.SALARY_MIN]) {
    clearErrors(FIELDS.SALARY_MIN)
  }

  if (fields[FIELDS.SALARY_MAX]) {
    clearErrors(FIELDS.SALARY_MAX)
  }

  if (!!fields[FIELDS.SALARY_MIN] && !!fields[FIELDS.SALARY_MAX] && !fields[FIELDS.RATE]) {
    const message = translations.rateError
    trigger([FIELDS.RATE, FIELDS.CURRENCY])
    return message
  }
  clearErrors([FIELDS.RATE, FIELDS.CURRENCY, FIELDS.SALARY_MIN, FIELDS.SALARY_MAX])
  return true
}

export const useLocationValidation = (withCountries?: boolean) => {
  const allLocations = useSelector(selectAllLocations(withCountries))
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)

  const validateLocationInput = (
    fields: Partial<IVacancySpecialityForm>,
    setError: UseFormSetError<IVacancySpecialityForm>,
    clearErrors: UseFormClearErrors<IVacancySpecialityForm>,
    translations: { [key: string]: string },
    locationInputValue?: string
  ): { isValid: boolean | string, found?: string } => {
    // The value inside the location input (not clicked enter yet)
    const isRequired = fields[FIELDS.LOCATION_TYPE]?.onsite
      || fields[FIELDS.LOCATION_TYPE]?.hybrid
    const value = (locationInputValue || '').trim()
    const locationArray = fields[FIELDS.LOCATIONS]

    if (!Array.isArray(locationArray)) return { isValid: false }
    if (isRequired && !locationArray?.length && !value) {
      setError(
        FIELDS.LOCATIONS,
        { type: 'custom', message: translations.locationError }
      )
      return { isValid: translations.locationError }
    }
    if (!value) {
      clearErrors(FIELDS.LOCATIONS)
      return { isValid: true }
    }
    if (locationArray.includes(
      value.replace(SYMBOLS.FE_LOCATION_SEPARATOR, SYMBOLS.BE_LOCATION_SEPARATOR)
    )) {
      const message = translations.locationExistError
      setError(
        FIELDS.LOCATIONS,
        { type: 'custom', message }
      )
      return { isValid: message }
    }

    if (locationArray.length === LENGTH_LIMITS.MAX.LOCATION_COUNT) {
      const message = translations.errorMsgLocationsLimit
      setError(
        FIELDS.LOCATIONS,
        { type: 'custom', message }
      )
      return { isValid: message }
    }

    const formattedValue = locationInputValue?.replace(
      SYMBOLS.FE_LOCATION_SEPARATOR, SYMBOLS.BE_LOCATION_SEPARATOR
    )
    const found = allLocations.cities.find(
      ([country, region, city]) => {
        return areStringsEqual(
          joinLocations(country, region, city, SYMBOLS.BE_LOCATION_SEPARATOR),
          formattedValue || ''
        )
      }
    )
    if (!found) {
      const message = isRequired && !value
        ? errorMsgsTranslations.wrongLocation
        : errorMsgsTranslations.vacanciesRequiredLocation
      setError(
        FIELDS.LOCATIONS,
        { type: 'custom', message }
      )
      return { isValid: message }
    }
    clearErrors(FIELDS.LOCATIONS)
    return { isValid: true, found: found.join(SYMBOLS.BE_LOCATION_SEPARATOR) }
  }

  return { validateLocationInput }
}

export const validateTagsInput = (
  tagArray: string[],
  fieldName: string,
  setError: UseFormSetError<Record<string, any>>,
  clearErrors: UseFormClearErrors<Record<string, any>>,
  translations: { [key: string]: string },
  tagInputValue?: string
) => {
  // The value inside the location input (not clicked enter yet)
  const value = (tagInputValue || '').trim()

  if (!Array.isArray(tagArray)) return false
  if (!value) {
    clearErrors(fieldName)
    return true
  }

  if (tagArray.length === LENGTH_LIMITS.MAX.TAGS_COUNT) {
    const message = translations.tagsLimitErrorMsg
    setError(
      fieldName,
      { type: 'custom', message }
    )
    return message
  }

  if (value.length > LENGTH_LIMITS.MAX.TAG_LENGTH) {
    const message = translations.helperTextLimit?.replace('%', `${LENGTH_LIMITS.MAX.TAG_LENGTH}`) || ''
    setError(
      fieldName,
      { type: 'custom', message }
    )
    return message
  }

  if (tagArray.some((l) => areStringsEqual(l, value))) {
    const message = translations.helperTextRepeatTag
    setError(
      fieldName,
      { type: 'custom', message }
    )
    return message
  }

  clearErrors(fieldName)
  tagArray.push(value)
  return true
}

export const normalizeData = (
  data: { [key: string]: string | string[] | boolean | null | number | ILocationType }
) => {
  const normalized = {
    ...data, ...data.locationType as ILocationType
  } as { [key: string]: string | string[] | boolean | null | SocialLinkType[] }

  delete normalized.audience
  normalized.specialities = [normalized.jobName as string]
  delete normalized.locationType
  if (!normalized.salaryMin && !normalized.salaryMax) {
    normalized.currency = null
    normalized.payPeriod = null
  }
  if (Array.isArray(normalized.links)) {
    if (normalized.links.length) {
      const links = normalized.links as SocialLinkType[]
      normalized.url = links[0].url
    }
    delete normalized.links
  }
  normalized.url = normalized.url || null
  Object.keys(normalized).forEach((key) => {
    if (data[key] === '' || data[key] === 0) {
      normalized[key] = null
    }
  })

  return normalized
}

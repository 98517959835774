import { useSelector } from 'react-redux'
import { LimitExceededTrustModal } from 'features/LimitExceededTrustModal'
import { getIsFullTrustContacts, getIsOpenContactsModal } from 'features/MyProfile/selectors'
import { TrustUsersModal } from 'features/TrustUsersModal'
import { ContactActionsProvider } from 'providers/ContactActionsProvider'

export const Modals = () => {
  const isOpenContactsModal = useSelector(getIsOpenContactsModal)
  const isFullTrustContacts = useSelector(getIsFullTrustContacts)

  return (
    <>
      {isOpenContactsModal && (
        <ContactActionsProvider>
          <TrustUsersModal isOpenContactsModal={isOpenContactsModal} />
        </ContactActionsProvider>
      )}
      {isFullTrustContacts && <LimitExceededTrustModal isFullTrustContacts={isFullTrustContacts} />}
    </>
  )
}

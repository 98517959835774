import {
  FC, memo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { addContact } from 'features/MyProfile/actions'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectMyNetworkTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { GhostButton } from '../Button_V2/GhostButton'
import styles from './styles.module.sass'

interface IContact {
  name: string,
  photoUrl: string,
  active: boolean,
  onClick: () => void,
  action?: (uid: string) => void,
  searchString?: string,
  uid: string
  photo: string,
  jobTitle: string,
  isMocUser?: boolean,
  isContact?: boolean
  isTrusted: boolean,
  showTrustButtons?: boolean
}

export const Contact: FC<IContact> = memo(({
  name,
  photoUrl,
  active = false,
  onClick,
  searchString,
  photo,
  uid,
  jobTitle = '',
  isMocUser = false,
  isContact = false,
  showTrustButtons = false,
  isTrusted,
  action
}) => {
  const dispatch = useDispatch()
  const profileItemRef = useRef(null)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isLoading, setIsLoading] = useState(false)

  const addNewContact = async (uid: string, isExistingContact: boolean) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className={cn(styles.profileItem, active && styles.activeItem, isMocUser && styles.mocUserStyles)}
      ref={profileItemRef}
      onClick={onClick}
    >
      <ImageWithTrustMark
        uid={uid}
        photoURL={photoUrl}
        photo={photo}
        alt={name}
        width={56}
        className={styles.imgContainer}
      />
      <div className={styles.profileContent}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.name}>
          {colorTheMatch(name, searchString || '')}
        </Typography>
        <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="p" className={styles.jobTitle}>
          {colorTheMatch(jobTitle, searchString || '')}
        </Typography>
      </div>
      {!!action && !isMocUser && !isTrusted && isContact && showTrustButtons && (
        <GhostButton
          title={
            isTrusted
              ? otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST]
              : otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST]
          }
          onClick={(e) => {
            e.stopPropagation()
            action(uid)
          }}
        />
      )}
      {!isMocUser && !isContact && !showTrustButtons && (
        <GhostButton
          title={myNetworkTranslations.myNetworkAddContactButton}
          onClick={(e) => {
            e.stopPropagation()
            addNewContact(uid, isContact)
          }}
        />
      )}
    </div>
  )
})

import { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { StateType } from 'features/FilterItems/types'
import { LocationState } from 'features/Listing/types'
import styles from './styles.module.sass'

interface IFilterItems {
  items: {
    id: number
    name: string
  }[]
  setActiveFilters: (filters: number[]) => void
  activeFilters: number[]
  defaultFilter?: number
  filtersContent?: string
  allowMultiple?: boolean
  resetSelectedCardOnClick?: boolean
  state?: StateType
}

export const FilterItems: FC<IFilterItems> = ({
  items,
  setActiveFilters,
  activeFilters,
  defaultFilter,
  filtersContent,
  state = StateType.DEFAULT,
  allowMultiple = true,
  resetSelectedCardOnClick = false
}) => {
  const history = useHistory()
  const location = useLocation<LocationState>()

  useEffect(() => {
    if (activeFilters.length === 0 && defaultFilter) {
      setActiveFilters([defaultFilter])
    }
  }, [activeFilters, defaultFilter, setActiveFilters])

  const handleClick = (item: { id: number; name: string }) => {
    if (activeFilters.includes(item.id)) {
      setActiveFilters(activeFilters.filter((id) => id !== item.id))
    } else if (allowMultiple) {
      setActiveFilters([...activeFilters, item.id])
    } else {
      setActiveFilters([item.id])
    }
    if (resetSelectedCardOnClick) {
      history.push({
        state: location.state,
        search: ''
      })
    }
  }

  const getCurrentState = (id: number) => {
    return activeFilters?.includes(id) ? StateType.ACTIVE : state
  }

  const stateStyleMap: { [key in StateType]?: string } = {
    [StateType.LIGHT]: styles.lightFilter,
    [StateType.DEFAULT]: styles.defaultFilter,
    [StateType.ACTIVE]: styles.active
  }

  return (
    <div className={cn(styles.filters, filtersContent)}>
      {items.map((item) => (
        <GhostButton
          title={item.name}
          onClick={() => handleClick(item)}
          key={item.id}
          className={cn(
            styles.filtersButton,
            stateStyleMap[getCurrentState(item.id)]
          )}
        />
      ))}
    </div>
  )
}
